import {inject, Injectable} from '@angular/core';
import {ProgressService} from "../../oex-ui-kit/services/progress.service";
import {FilterComponent, IFilter} from "../../oex-ui-kit/components/filter/filter.component";
import {APIService} from "./api.service";
import {ActivatedRouteSnapshot, ResolveFn} from "@angular/router";
import {StatusService} from "./status.service";
import {StorageService} from './storage.service';
import {IPagingData} from '../../oex-ui-kit/components/grid/grid';
import {IStartupListData} from './api.types';
import {getPageGridModel, IPageWithGridModel} from '../../oex-ui-kit/base/page-with-grid.component';

export interface ITrackDataModel extends IPageWithGridModel<IStartupListData> {
  track: string;
  storeKey: string;
}

export const trackPageModelResolver: ResolveFn<ITrackDataModel> = async (route: ActivatedRouteSnapshot) => {
  const st = inject(StorageService);
  const track = route.paramMap.get('track') || '';
  const model: ITrackDataModel = {
    ...getPageGridModel<IStartupListData>(route, [
      {id: 'status', label: 'General Status', width: '20%'},
      {id: 'gstatus', label: 'Grant Status', parameter: 'status', width: '20%'},
      {id: 'location', label: 'Location', parameter: 'location', width: '20%', multiple: true },
      {id: 'user_deal_lead', label: 'Deal Lead', parameter: 'deal_lead', width: '20%'},
      {id: 'industry', label: 'Industry', width: '15%'},
      {id: 'year', label: 'Year', width: '15%'},
      {id: 'user_sales', label: 'Account Manager', width: '20%' },
      /*{id: 'month', label: 'Month', width: '15%', items: [
          {id: 1, value: 'January'},
          {id: 2, value: 'February'},
          {id: 3, value: 'March'},
          {id: 4, value: 'April'},
          {id: 5, value: 'May'},
          {id: 6, value: 'June'},
          {id: 7, value: 'July'},
          {id: 8, value: 'August'},
          {id: 9, value: 'September'},
          {id: 10, value: 'October'},
          {id: 11, value: 'November'},
          {id: 12, value: 'December'}
        ]},*/
      {id: 'customer_date', label: 'Customer', type: 'checkbox', width: '10%'},
      {id: 'overdue', label: 'Overdue', type: 'checkbox', width: '10%'},
      {id: 'granted', label: 'Granted', type: 'checkbox', width: '10%'}
    ]),
    track,
    storeKey: 'csh-filters-track-' + track,
  };
  FilterComponent.loadFilterValuesFromStorage(model.filters, st, model.storeKey);

  // Check visibility for filters
  const flt = model.filters?.find(f => f.id === 'customer_date');
  if (flt) {
    flt.hidden = track !== 'venture-fund';
  }


  model.data = await inject(TrackDataService).requestData(track, model.search, model.sorting, model.filters, model.page);
  return model;
}

export const trackYearsResolver = () => {
  return inject(APIService).getStartupYears();
}

/*export const trackAccountManagerResolver = () => {
  return inject(APIService).getAccountManagerList();
}*/

export const statusesResolver = () => {
  return inject(StatusService).requestStatuses();
}

@Injectable({
  providedIn: 'root'
})
export class TrackDataService {
  private progress = inject(ProgressService);
  private api = inject(APIService);


  async requestData(track: string, term: string = '', sorting = '', filter?: IFilter, page = 1) {
    return this.api.getStartups(
      track,
      page,
      term,
      filter,
      sorting
    );
  }
}
