import { Injectable, inject } from '@angular/core';
import {ActivatedRoute, ActivationEnd, NavigationEnd, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {filter} from "rxjs";
import {DOCUMENT} from "@angular/common";

export interface IMeta {
  image: string;
  "image:secure_url": string;
  description: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private meta = inject(Meta);
  private ts = inject(Title);
  private document = inject<Document>(DOCUMENT);


  initialize() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(e => {
        this.updateMetaTags(this.route);
      });
  }

  // Recursive function to update meta tags
  private updateMetaTags(route: ActivatedRoute) {
    const data = route.snapshot.data;
    if (data && data['meta']) {
      this.setMetaTags(data['meta']);
    }

    // Recursive call for child routes
    route.children.forEach(child => this.updateMetaTags(child));
  }

  // Function to update meta description
  private setMetaTags(meta: any) {
    for (let key of Object.keys(meta)) {
      this.meta.updateTag({ name: key, content: meta[key] });
      this.meta.updateTag({ name: 'og:' + key, content: meta[key] });
    }
    this.meta.updateTag({ name: 'url', content: this.document.location.href });
    this.meta.updateTag({ name: 'og:url', content: this.document.location.href });
  }

  setTitle(title: string) {
    this.ts.setTitle(title);
  }
}
